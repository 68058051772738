import {
  ref, computed,
} from '@vue/composition-api'

import {
  arrSecurityScanner, popupsArrErr, successArr,
} from './arrs'
import UpdateSystem from './components/updateSystem'
import UpdateAntivirus from './components/updateAntivirus'
import UpdateOffice from './components/updateOffice'
import DisabledDevice from './components/disabledDevice'
import updateAutostart from './components/updateAutostart'

import LoadWindow from './components/loadWindow'

export default {
  name: 'securityScanner',
  props: {
    disabled: Boolean,
  },
  components: {
    UpdateSystem,
    UpdateAntivirus,
    LoadWindow,
    UpdateOffice,
    DisabledDevice,
    updateAutostart,
  },

  setup(props, ctx) {
    const { $router } = ctx.root
    const { emit } = ctx
    const active = ref(true)

    const validDevice = ref(false)
    const validAutoStart = ref(false)

    const securityScannerProxy = ref(arrSecurityScanner())

    const lengthArr = computed(() => securityScannerProxy?.value?.filter(item => Object.keys(item)?.indexOf('update') > 0)?.length)

    const step = ref(0)
    const popups = ref([])


    const popupName = ref('')

    const loadWindow = ref(false)
    const updateSystem = ref(false)

    const changePopupName = (item) => {
      if (item.key.indexOf('wrong') !== -1) {
        emit('error', { text: 'Ошибка! Есть более критичные обновления.' })
        return
      }

      if (!item.update) {
        popupName.value = item.key
        if (item.key !== 'disabledDevice') {
          emit('message', successArr[item.key])
        }
      }
    }

    const disableMessage = () => {
      emit('message', successArr.disabledDevice)
    }

    const loadFinish = (val) => {
      emit('capsuleListCurrentNum', val)
      loadWindow.value = false
      // popupName.value = ''
      const arrUpdate = []

      securityScannerProxy.value.forEach((e) => {
        if (val.keyName === e.key) {
          if (e.key === 'updateAutostart' && !validAutoStart.value) {
            emit('errorScanner', popupsArrErr[0])
            return
          }

          if (e.key === 'disabledDevice' && !validDevice.value) {
            emit('errorScanner', popupsArrErr[0])
            return
          }
          e.update = true
          popups.value = [...popups?.value?.filter(item => Object.keys(item)?.indexOf('errSuccess') === -1)]
        }
        if (e.key && e.key.indexOf('wrong') === -1) {
          arrUpdate.push(e)
        }
      })
      if (arrUpdate.every(e => e.update) && (lengthArr.value === arrUpdate.length)) {
        active.value = true
        emit('success')
        // pushPopup(popupsArr, popups.value)
      }
      const find = securityScannerProxy.value?.find(({ key }) => key === val?.keyName)
      if (find.update) {
        popupName.value = ''
      }
    }

    const nextStep = () => {
      $router.push('/education2/lesson4')
    }

    const changeValidDevice = (val) => {
      validDevice.value = val
    }

    const changeValidAutoStart = (val) => {
      validAutoStart.value = val
    }

    const close = () => {
      popupName.value = null
    }

    return {
      active,
      step,
      popupName,
      updateSystem,
      loadWindow,
      securityScannerProxy,
      popups,
      disableMessage,
      close,
      nextStep,
      changePopupName,
      loadFinish,
      changeValidDevice,
      changeValidAutoStart,
    }
  },
}
