import { ref } from '@vue/composition-api'

import SecurityScanner from './components/securityScanner'
import { pushPopup } from '@/utils/pushPopup'
import { popupArr, popupsArrSuccess } from './arrs'

export default {
  name: 'step1',
  components: {
    SecurityScanner,
  },
  setup(props, ctx) {
    const popups = ref(popupArr)
    const disabled = ref(false)
    const capsuleList = ref([
      {
        title: 'Обновления', img: 'cloud', currentNum: 0, key: 'updateSystem',
      },
      {
        title: 'Offices', img: 'office', currentNum: 4, key: 'updateOffice',
      },
      {
        title: 'Антивирус', img: 'antivirus', currentNum: 2, key: 'updateAntivirus',
      },
    ])

    const capsuleListChange = (key) => {
      capsuleList.value.forEach((e) => {
        if (e.key === key.keyName) e.currentNum = 0
      })
    }

    const securityScanner = ref(false)

    const openScanner = () => {
      if (securityScanner.value) return

      securityScanner.value = true
      popups.value = []
      pushPopup([
        { text: 'Ого! Сколько тут неправильных настроек! Давай их исправим.' },
        { text: 'Выбери настройки, которые нужно исправить в первую очередь (влияют на безопасность компьютера).' },
      ], popups.value)
    }

    const handleMessage = (mess) => {
      popups.value.push(mess)
    }
    const handleError = () => {
      popups.value.push({
        text: 'Это не самое важное обновление, найди более критичное.',
      })
    }
    const handleErrorScanner = (mess) => {
      popups.value.push(mess)
    }

    const nextStep = () => {
      ctx.root.$router.push('/education2/lesson4')
    }

    const handleSuccess = () => {
      disabled.value = true
      pushPopup(popupsArrSuccess, popups.value)
    }

    return {
      securityScanner,
      capsuleList,
      popups,
      disabled,
      nextStep,
      capsuleListChange,
      openScanner,
      handleError,
      handleMessage,
      handleSuccess,
      handleErrorScanner,
    }
  },
}
